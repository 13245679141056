import { css } from '@emotion/core';
import colors from '../../../../../styles/colors';

export const mainTable = css`
  &.mainTable {
    width: 100%;
  }

  .styleForNumbers {
    display: flex;
    justify-content: end !important;
  }

  .nameFixedWidth {
    width: 15%;
  }

  .fixBorder {
    border: 3px solid #e1f4fd;
  }

  th {
    padding-right: 0.4rem;
  }

  td {
    padding-left: 1rem;
    padding-right: 0.5rem;
  }

  &.gray-table {
    .tableHeading {
      background: ${colors.gray[50]};
      color: ${colors.gray[800]};

      th:last-child {
        > div {
          justify-content: end;
        }
      }
    }
    tbody tr {
      border-bottom: 1px solid ${colors.neutralGray[50]};
      &:hover,
      &:active,
      &:focus {
        background: ${colors.neutralGray[50]};
      }
    }
  }
`;

export const tableHeading = css`
  background: #e1f4fd;
  height: 45px;
  padding-left: 23px;
  padding-right: 23px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: #3a498e;
  position: sticky;
  top: 0;
  z-index: 9;
`;

export const recruiterNameCell = css`
  color: #607d8b;
  font-size: 14px;

  &.name {
    margin-right: 20px;
  }

  &.low,
  &.faible {
    color: #b33636;
  }
  &.medium,
  &.oyen {
    color: #938511;
  }

  &.high,
  &.haut {
    color: #5c995e;
  }
`;

export const sortIconWrapper = css`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;
